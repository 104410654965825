<template>
  <v-dialog persistent :value="dialogShow" style="margin:10px" :max-width="440">
    <v-col class="coins-Tips-card">
      <v-row class="coins-Tips-card-title" no-gutters :class="$vuetify.breakpoint.mdAndDown ? ' py-5 px-5' : 'py-5 px-5'">
        <v-col cols="11">
          <label class="dialog-title white--text ma-0 text-capitalize d-block full-width">{{ $t(`label.rewardFreeCoins`) }}</label>
        </v-col>
        <v-col cols="1">
          <v-icon class="dialog-close-icon" @click="closeAction">mdi-close</v-icon>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-center align-center">
          <div class="my-coins">
            <span>{{ $t(`label.myCoins`) }}</span>
            <div class="d-flex">
              <img class="img-my-coins" src="/static/image/cardMania/winning-coin.png" alt="winning" />
              <span class="coins">{{ isLoggedIn && memberInfo.rewardBalance ? memberInfo.rewardBalance : 0 }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center align-center upper-coinTips mt-3">{{ $t(`label.coinTipsDes1`) }}</v-col>
        <v-col cols="12" class="d-flex justify-center align-center lower-coinTips">{{ $t(`label.coinTipsDes2`) }}</v-col>
      </v-row>
      <div>
        <!-- First v-row with 3 containers -->
        <v-row no-gutters class="d-flex justify-space-around align-center gain-coin-container">
          <v-col v-for="item in GainCoins" :key="item.id" cols="4" md="4">
            <!-- Container for each item -->
            <div class="custom-container text-center">
              <!-- Image -->
              <img :src="`/static/image/rewardStore/popUp-${item.shortName}.png`" alt="Item Image" class="item-image-pop-up" />

              <!-- Name -->
              <div class="item-name">{{ item.name }}</div>

              <!-- Button -->
              <v-col cols="12" class="item-button-container">
                <v-btn v-if="isLoggedIn" @click="getRouteLink(item)" class="item-button d-flex align-center justify-center" :disabled="disabledCardMania(item.shortName)">{{ disabledCardMania(item.shortName) ? $t('label.coinTipsComingSoon'): item.btn }}</v-btn>
                <v-btn v-else @click="openLoginDialog" class="item-button d-flex align-center justify-center" :disabled="disabledCardMania(item.shortName)">{{ disabledCardMania(item.shortName) ? $t('label.coinTipsComingSoon'): item.btn }}</v-btn>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-center align-center upper-coinTips">{{ $t(`label.coinTipsDes3`) }}</v-col>
        <v-col cols="12" class="d-flex justify-center align-center lower-coinTips">{{ $t(`label.coinTipsDes4`) }}</v-col>
      </v-row>
      <div>
        <v-row no-gutters class="d-flex justify-center align-center">
          <v-col v-for="item in SpentCoins" :key="item.id" cols="4" md="4">
            <!-- Container for each item -->
            <div class="custom-container text-center">
              <!-- Image -->
              <img :src="`/static/image/rewardStore/popUp-${item.shortName}.png`" alt="Item Image" class="item-image-pop-up" />

              <!-- Name -->
              <div class="item-name">{{ item.name }}</div>

              <!-- Button -->
              <v-col cols="12" class="item-button-container">
                <!--                <router-link>-->
                <v-btn @click="getRouteLink(item)" class="item-button d-flex align-center justify-center" :disabled="item.disabled">{{ item.disabled ? $t('label.coinTipsComingSoon'): item.btn }}</v-btn>
                <!--                </router-link>-->
                <!--                <router-link @click="closeAction" :to="{ name: item.route, params: { lang: uiHelper.getRegionLocale() } }">-->
                <!--                  <v-btn class="item-button d-flex align-center justify-center">{{ item.btn }}</v-btn>-->
                <!--                </router-link>-->
              </v-col>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-center align-center upper-coinTips">
          <v-btn class="item-button-close d-flex align-center justify-center" @click="closeAction">{{ $t('label.close') }}</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-dialog>

</template>

<script>
import {ROUTE_NAME} from "@/constants/route.constants";
import {locale, uiHelper} from "@/util";
import {REWARD_CONFIGURATION_STATUS} from "@/store/reward.module";
import { DevicePlatform } from "@/constants/enums";

export default {
  name: "coinTipsPopUp",
  props: {
    arrayInfo: {
      type: Array,
      required: false,
      default: null
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 500
    },
    closeAction: {
      required: false,
      default: null
    },
    dialogShow: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    coinTipsDialog: true,
    GainCoins: [
      { id: 1, name: locale.getMessage('label.coinTipsDeposit'), shortName: 'deposit', btn: locale.getMessage('label.coinTipsDepositBtn'), route: 'funds' },
      { id: 2, name: locale.getMessage('label.coinTipsCheckIn'), shortName: 'daily', btn: locale.getMessage('label.coinTipsCheckInBtn'), route: 'rewards' },
      { id: 3, name: locale.getMessage('label.coinTipsCardMania'), shortName: 'cardMania', btn: locale.getMessage('label.coinTipsCardManiaBtn'), route: 'card-mania' }
    ],
  }),
  created() {
    this.getConfigurationStatus()
  },
  computed: {
    SpentCoins() {
      return [
        {
          id: 1,
          name: locale.getMessage('label.coinTipsRewardStore'),
          shortName: 'rewardStore',
          btn: locale.getMessage('label.coinTipsRewardStoreBtn'),
          route: 'rewardStore',
          disabled: !this.rewardStoreEnabled // Negating the rewardStoreEnabled to make it disabled when reward store is not enabled
        },
        { id: 2, name: locale.getMessage('label.coinTipsKrikyaWheel'), shortName: 'spin', btn: locale.getMessage('label.coinTipsKrikyaWheelBtn'), route: 'wheel-of-fortune', disabled: false }
      ];
    },
    rewardStoreEnabled() {
      return this.configuration.reward_store.enabled;
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    configuration() {
      return this.$store.state.reward.configurationStatus;
    }
  },
  watch: {
    memberInfo() {
      return this.$store.state.member.info
    },
    configuration() {
      return this.$store.state.reward.configurationStatus;
    }
  },
  methods: {
    updateRouteForKrikyaWheel() {
      // Find the item with the route 'rewards'
      const krikyaWheel = this.SpentCoins.find(item => item.name === locale.getMessage('label.coinTipsKrikyaWheel'));

      // Update the route based on the condition
      if (krikyaWheel) {
        krikyaWheel.route = uiHelper.getPlatform() === DevicePlatform.DESKTOP ? ROUTE_NAME.WOF : ROUTE_NAME.MWOF;
      }
    },
    getRouteLink(item) {
      this.updateRouteForKrikyaWheel()
      this.closeAction();
      this.$router.push({
        name: item.route,
        params: { lang: uiHelper.getRegionLocale() }
      })
    },
    getConfigurationStatus() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${REWARD_CONFIGURATION_STATUS}`, {filter})
    },
    disabledCardMania(shortName) {
      if(shortName == 'cardMania') {
        if(this.configuration.card_mania) {
          return !this.configuration.card_mania.enabled;
        }
        return true;
      }
      return false;
    },
    // redirectAndClose() {
    //   // Call the closeDialog method to close the dialog
    //   this.closeDialog();
    //
    //   // Use Vue Router to navigate to the specified route
    //   this.$router.push(this.getRouteLink(item));
    // },
    closeDialog() {
      // Implement your dialog closing logic here
      // For example, you may emit an event or use a Vuex action to update the dialog state
    },
    openCoinTipsDialog() {
      this.coinTipsDialog = true
    },
    closecoinTipsDialog() {
      this.coinTipsDialog = false
    },
    openLoginDialog() {
      this.closeAction()
      this.$parent.openLoginDialog()
    }
  }
}
</script>

<style lang="scss">
.upper-coinTips {
  text-align: center;
  text-transform: uppercase;
  color: #001958;
  font-size: 14px;
  font-weight: bold;
}

.lower-coinTips {
  text-align: center;
  color: #000000;
  font-size: 11px;
  font-weight: 600;
}
.custom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 2px solid #14805e;
  border-radius: 5px;
  padding: 10px 0px; /* Add padding as needed */
  margin: 10px !important;
  height: 120px;

  @media (max-width: 424px) {
    margin: 10px 2px !important;
  }
}
.item-image-pop-up {
  width: 50px; /* Adjust the width as needed */
  height: 50px;
}
.item-name {
  width: 100%;
  font-weight: bold;
  font-size: 9px;
  white-space: nowrap;

  @media (max-width: 450px)  {
    font-size: 8px;
  }
}
.item-button {
  font-size: 10px !important;
  width: 100%;
  text-transform: none !important;
  background-color: #f56908 !important;
  color: #fff !important;
  height: 27px !important;
}
.item-button-close {
  background-color: var(--v-primary-base) !important;
  border-radius: 5px;
  color: #fff !important;
  width: 90% !important;
  margin-bottom: 10px;
}
.coins-Tips-card {
  background-color: #fff;
  padding: 0;
  border-radius: 4px;
  .coins-Tips-card-title {
    background-color: var(--v-primary-base);
    border-radius: 4px 4px 0 0;
  }
  .dialog-close-icon {
    color: #fff !important;
  }
}
.close_instant {
  position: absolute;
  width: auto;
  float: right;
  top: 37px;
  right: 2%;
  z-index: 99999;
}

.box_instant_login {
  height: 450px!important;
  //overflow: auto !important;
  box-shadow: none !important;
}

.popupImage {
  text-align: center;
}

.popupImage > img {
  text-align: center;
  width: 430px;
  height: auto;
  aspect-ratio: 430/532;
  padding: 5px;
  border-radius: 20px;
}

@media (max-width: 750px) {
  .popupImage > img {
    width: 385px;
  }

  .close_instant {
    right: 20px;
  }
}

@media (max-width: 450px) {
  .popupImage > img {
    width: 305px;
  }

  .close_instant {
    right: 5px;
  }
}

.max_height_setting {
  max-height: 270px;
  overflow-y: auto;
}

.my-coins {
  display: flex;
  width: 100%;
  height: 56px;
  margin: 12px 12px;
  padding: 4px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 2px rgba(120,120,120,0.46);
  -webkit-box-shadow: 1px 1px 12px 2px rgba(120,120,120,0.46);
  -moz-box-shadow: 1px 1px 12px 2px rgba(120,120,120,0.46);
  font-size: 18px;

  .img-my-coins {
    width: 36px !important;
    height: 36px !important;
    max-width: 36px !important;
    max-height: 36px !important;
    margin-right: 6px;
  }

  .coins {
    font-weight: bold;
    align-self: center;
    color: var(--v-secondary-base);
  }
}

.gain-coin-container {
  margin: 0 4px !important;
}

</style>