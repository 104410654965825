<template>
  <v-dialog persistent :value="dialogShow" :max-width="maxWidth" style="position:relative;" content-class="box_instant">
    <v-icon
        size="30px"
        :class="['dialog-close-icon white--text close_instant']" color="white"
        @click="closeAction" v-if="closeAction != null" alt="close button">mdi-close-circle-outline
    </v-icon>
    <v-card class="dialog-card" style="border-radius:10px;" color="transparent">
      <v-row no-gutters class="dialog-header">
        <v-carousel
            cycle
            height="auto"
            interval="8000"
            hide-delimiter-background
            :hide-delimiters="arrayInfo.length <=1"
            :show-arrows="arrayInfo.length >1"
            show-arrows-on-hover
        >
          <v-carousel-item
              v-for="(k,i) in arrayInfo"
              :key="i"
          >
            <v-sheet
                height="100%"
                color="transparent"
            >
              <v-row
                  @click="goToPromoPage()"
                  class="fill-height"
                  align="center"
                  justify="center"
              >
                <v-col cols="12" class="pa-0 popupImage"
                       v-html="k.message.replace(/<[p>]*>/g, ' ').replace(/<[/p>]*>/g, ' ')"></v-col>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {ROUTE_NAME} from "@/constants/route.constants";

export default {
  name: "InstantPopupDialog",
  props: {
    arrayInfo: {
      type: Array,
      required: false,
      default: null
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 500
    },
    closeAction: {
      required: false,
      default: null
    },
    dialogShow: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  methods:{
    goToPromoPage(){
      this.closeAction();
      this.$router.push({
        name: ROUTE_NAME.PROMOTION
      })
    }
  }
}
</script>

<style>
.close_instant {
  position: absolute;
  width: auto;
  float: right;
  top: 17px;
  right:2%;
  z-index: 99999;
}

.v-dialog.box_instant {
  height: 544px;
  box-shadow: none !important;
}

.popupImage {
  text-align: center;
}

.popupImage > img {
  text-align: center;
  width: 430px;
  height: auto;
  aspect-ratio: 430/532;
  padding: 5px;
  border-radius: 20px;
}

@media (max-width: 750px) {
  .popupImage > img {
    width: 385px;
  }

  .close_instant {
    right: 20px;
  }
}

@media (max-width: 450px) {
  .popupImage > img {
    width: 305px;
  }

  .close_instant {
    right: 5px;
  }
}

</style>