<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'mobile' : ''">
    <v-row no-gutters justify="space-between">
      <v-col class="no-gutters" cols="10">
        <label style="float:left" v-if="enabledLabel" class="input-field-label ma-0 text-capitalize d-block">
          {{ label }}
          <span v-if="!isOptional" class="red--text ml-1">*</span>
        </label>
      </v-col>
      <v-col class="no-gutters" cols="2">
        <v-tooltip right v-if="tooltip !== ''">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" style="float:right" v-bind="attrs" v-on="on">
              help
            </v-icon>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </v-col>
      <v-col class="no-gutters" cols="12">
        <v-text-field
          :error-messages="errorMsg"
          v-if="type == 'text' || type == 'password'"
          class="input-field elevation-0 hide-details"
          :class="customClass"
          :value="value"
          outlined
          :readonly="readonly"
          :disabled="readonly"
          :label="hint"
          :placeholder="placeholder"
          :prefix="prefix"
          :persistent-hint="hint != ''"
          :type="type == 'password' ? (showPassword ? 'text' : 'password') : type"
          @click:append="type == 'password' ? (showPassword = !showPassword) : (showPassword = !showPassword)"
          :append-icon="type == 'password' && allowShowPassword ? (showPassword ? 'mdi-eye' : 'mdi-eye-off') : icon"
          :prepend-inner-icon="prependInnerIcon"
          :rules="isOptional ? (value.length > 0 ? rules : []) : rules"
          @input="input"
          @change="change"
          @keyup="keyup"
          @keydown.delete="deleteText"
          @blur="blur"
          :clearable="clearable"
          :solo="solo"
          autocomplete="new-password"
          :autofocus="true"
        >
          <template v-slot:append-outer>
            <slot name="form-field-append-outer"></slot>
          </template>
        </v-text-field>

        <v-select
          v-if="type == 'select'"
          class="input-field"
          :class="customClass"
          :items="items"
          :value="value"
          outlined
          :disabled="disabled"
          :label="hint"
          :placeholder="placeholder"
          :hide-details="hint == '' && rules == null"
          :persistent-hint="hint != ''"
          :rules="isOptional ? (value.length > 0 ? rules : []) : rules"
          :item-text="displayText"
          item-value="value"
          @input="input"
          @change="change"
          :clearable="clearable"
        ></v-select>

        <v-dialog v-model="display" :width="330" v-if="type == 'datetime'">
          <template v-slot:activator="{ on }">
            <v-text-field
              class="input-field elevation-0 hide-details"
              outlined
              :label="hint"
              :persistent-hint="hint != ''"
              :placeholder="placeholder"
              :value="value"
              v-on="on"
              :rules="rules"
              :readonly="true"
            ></v-text-field>
          </template>

          <v-card>
            <v-card-text class="px-0 py-0">
              <v-tabs fixed-tabs v-model="activeTab" color="primary" class="tabs_calendar">
                <v-tab key="calendar">
                  <slot name="dateIcon">
                    <v-icon>event</v-icon>
                  </slot>
                </v-tab>
                <v-tab key="timer" :disabled="dateSelected" v-if="type == 'datetime'" @click="showTimePicker">
                  <slot name="timeIcon">
                    <v-icon>mdi-clock-outline</v-icon>
                  </slot>
                </v-tab>
                <v-tab-item key="calendar">
                  <v-date-picker v-model="date" @input="showTimePicker" full-width color="primary" :max="maxDate"
                                 :locale="language" scrollable class="custom-date-picker"></v-date-picker>
                </v-tab-item>
                <v-tab-item key="timer">
                  <v-time-picker ref="timer" class="v-time-picker-custom" v-model="time" use-seconds ampm-in-title
                                 :max="maxTime" color="primary" style="width:100%"
                                 :locale="language"></v-time-picker>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <slot name="actions" :parent="this">
                <v-btn color="primary" text @click="okHandler">{{ $t('label.confirm') }}</v-btn>
              </slot>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-file-input v-if="type == 'fileinput'" :label="label" class="input-field" prepend-icon outlined @input="input" @change="change" :value="value"></v-file-input>
        <!-- <v-row no-gutters v-if="type == 'phoneNo'">
      <v-col cols=2>
      </v-col>
      <v-col cols=10>
      </v-col> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { uiHelper } from '@/util'
import { DATE } from '@/constants/constants'
import { format } from 'date-fns'
import moment from 'moment'

const DEFAULT_DATE = new Date()
const DEFAULT_START_TIME = '00:00:00'
const DEFAULT_END_TIME = '23:59:59'
const DEFAULT_CLEAR_TEXT = 'CLEAR'

export default {
  name: 'AgentAppFormField',
  model: {
    prop: 'value'
  },
  props: {
    solo: {
      required: false,
      default: false
    },
    value: {
      required: true,
      default: ''
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    hint: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    prependInnerIcon: {
      type: String,
      required: false,
      default: ''
    },
    rules: {
      type: Array,
      required: false,
      rules: () => []
    },
    isOptional: {
      type: Boolean,
      required: false,
      default: false
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    displayText: {
      required: false,
      default: 'displayText'
    },
    change: {
      type: Function,
      required: false,
      default: function() {}
    },
    deleteText: {
      type: Function,
      required: false,
      default: function() {}
    },
    keyup: {
      type: Function,
      required: false,
      default: function() {}
    },
    allowShowPassword: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    enabledLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    errorMsg: {
      type: String,
      required: false
    },
    blur: {
      type: Function,
      required: false,
      default: function() {}
    },
    maxDate: {
      type: String,
      default: undefined
    },
    usage: {
      type: String,
      required: false,
      default: 'startdate'
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT
    }
  },
  data() {
    return {
      showPassword: false,
      language: uiHelper.getLanguage(),
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: this.usage == 'startdate' ? DEFAULT_START_TIME : DEFAULT_END_TIME,
      maxTime: ''
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    dateTimeFormat() {
      if (this.type == 'date') {
        return DATE.SHORT
      } else {
        return DATE.LONG
      }
    },
    defaultDateTimeFormat() {
      if (this.type == 'date') {
        return DATE.SHORT
      } else {
        return DATE.LONG
      }
    },
    formattedDatetime() {
      return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : ''
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + ' ' + this.time
        return datetimeString
      } else {
        return null
      }
    },
    dateSelected() {
      return !this.date
    }
  },
  watch: {
    value: function() {
      this.init()
    }
  },
  methods: {
    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault()
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, '')
    },
    input(value) {
      this.$emit('input', value)
    },
    init() {
      if (this.value == null) {
        return
      } else if (this.value == '') {
        let iniDateTime = new Date()
        this.date = moment(String(iniDateTime)).format(DATE.SHORT)
        this.time = moment(String(iniDateTime)).format(DATE.TIME)
      } else {
        this.date = moment(String(this.value)).format(DATE.SHORT)
        this.time = moment(String(this.value)).format(DATE.TIME)
      }
    },
    okHandler() {
      this.resetPicker()
      this.date = moment(String(this.date)).format(DATE.SHORT)
      if (this.type == 'date') {
        this.$emit('input', this.date)
      } else if (this.type == 'datetime') {
        this.$emit('input', this.date + ' ' + this.time)
      }
    },
    resetPicker() {
      this.display = false
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker() {
      if (this.type == 'datetime') {
        this.activeTab = 1
        this.maxTime = ''
        if (this.maxDate != '' && this.maxDate.substr(0, 10) == this.date) {
          this.maxTime = this.maxDate.substr(11, 8)
          this.time = this.maxDate.substr(11, 8)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.custom-readonly-field.v-text-field--readonly .v-input__control .v-input__slot {
  background-color: transparent !important;
}

.v-text-field--outlined {
  fieldset {
    border-width: 1px;
  }
}

.v-text-field--outlined.v-input--is-verify fieldset {
  border-color: var(--v-primary-base);
  border-width: 2px;
}

.input-field-label {
  font-size: 14px;
}

.v-input input,
.v-input textarea,
.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: var(--v-primary-base);
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin: 0px;
}

.input-field {
  .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    color: #000000;
  }

  .v-icon,
  .v-input__icon {
    color: #fbb70d;
  }

  .v-input__slot {
    margin: 0px;

    .v-select__slot {
      .v-select__selections {
        color: #000000;

        .v-select__selection--disabled {
          color: #c9c9c9 !important;
        }
      }

      .v-icon--disabled {
        color: #c9c9c9 !important;
      }
    }
  }

  .v-input__append-outer,
  .v-input__prepend-inner {
    margin-top: 0px !important;
    align-self: center;
  }

  .v-input__append-inner {
    margin: 0px;
    align-self: center;
  }

  &.v-input--is-readonly {
    input {
      color: #3a3a3a !important;
      opacity: 0.7;
    }

    .v-input__slot {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .v-input__control {
    .v-input__slot {
      min-height: 40px;
      height: 40px;
    }

    .v-text-field__details {
      margin-bottom: 0px !important;

      .v-messages__message {
        font-size: 11px;
      }
    }
  }

  .custom-date-picker .v-date-picker-table td {
    padding: 0px !important;
  }
}
</style>
