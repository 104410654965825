<template>
<div :class="$vuetify.breakpoint.mdAndDown ? 'mobile' : ''">
    <v-row no-gutters justify="space-between">
        <v-col no-gutters cols=10>
            <label style="float:left" v-if="enabledLabel" class="input-field-label ma-0 text-capitalize d-block">
                {{ label }}<span v-if="!isOptional" class="red--text ml-1">*</span></label>
        </v-col>
        <v-col no-gutters cols=2>
            <v-tooltip right v-if="tooltip !== ''">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" style="float:right" v-bind="attrs" v-on="on">
                        mdi-help-circle
                    </v-icon>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </v-col>
        <v-col no-gutters cols=12>
            <v-text-field 
              :error-messages="errorMsg" 
              v-if="type == 'text' || type == 'password'" 
              class="input-field elevation-0 hide-details" 
              :class="customClass"
              :value="value"
              outlined
              :readonly="readonly"
              :disabled="readonly"
              :label="hint"
              :placeholder="placeholder"
              :prefix="prefix" 
              :persistent-hint="hint != ''" 
              :type="type == 'password' ? (showPassword ? 'text' : 'password') : type" 
              @click:append="type == 'password' ? (showPassword = !showPassword) : (showPassword = !showPassword)" 
              :append-icon="type == 'password' && allowShowPassword ? (showPassword ? 'mdi-eye' : 'mdi-eye-off') : icon" 
              :prepend-inner-icon="prependInnerIcon" 
              :rules="isOptional ? (value.length > 0 ? rules : []) : rules" 
              @input="input" 
              @change="change" 
              @keyup="keyup"
              @keydown.delete="deleteText"
              @keydown.space="preventLeadingSpace"
              @keydown.native.space.prevent
              @blur="blur"
              :clearable="clearable"
              :solo="solo"
              autocomplete="new-password"
              :autofocus="true">
                <template v-slot:append-outer>
                    <slot name="form-field-append-outer"></slot>
                </template>
              <template v-slot:append>
                <slot name="form-field-append"></slot>
              </template>
            </v-text-field>

            <v-select
              v-if="type == 'select'"
              class="input-field"
              :class="customClass"
              :items="items"
              :value="value"
              outlined
              :disabled="disabled"
              :label="hint"
              :placeholder="placeholder"
              :hide-details="hint == '' && rules == null" :persistent-hint="hint != ''"
              :rules="isOptional ? (value.length > 0 ? rules : []) : rules"
              :item-text="displayText"
              item-value="value"
              @input="input"
              @change="change"
              :clearable="clearable">
            </v-select>

            <v-file-input v-if="type == 'fileinput'" :label="label" class="input-field" prepend-icon outlined @input="input" @change="change" :value="value"></v-file-input>
            <!-- <v-row no-gutters v-if="type == 'phoneNo'">
          <v-col cols=2>
          </v-col>
          <v-col cols=10>
          </v-col> -->
        </v-col>
    </v-row>
</div>
</template>

<script>
export default {
    name: 'AppFormField',
    props: {
        solo: {
            required: false,
            default: false,
        },
        value: {
            required: true,
            default: ''
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        prefix: {
            type: String,
            required: false,
            default: ''
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        prependInnerIcon: {
            type: String,
            required: false,
            default: ''
        },
        rules: {
            type: Array,
            required: false,
            rules: () => []
        },
        isOptional: {
            type: Boolean,
            required: false,
            default: false
        },
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        displayText: {
            required: false,
            default: 'displayText'
        },
        change: {
            type: Function,
            required: false,
            default: function () {}
        },
        deleteText: {
            type: Function,
            required: false,
            default: function () {}
        },
        keyup: {
            type: Function,
            required: false,
            default: function () {}
        },
        allowShowPassword: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        customClass: {
            type: String,
            required: false,
            default: ''
        },
        enabledLabel: {
            type: Boolean,
            required: false,
            default: false
        },
        tooltip: {
            type: String,
            required: false,
            default: ''
        },
        errorMsg: {
            type: String,
            required: false
        },
        blur: {
            type: Function,
            required: false,
            default: function () {}
        },
    },
    data: () => ({
        showPassword: false
    }),
    methods: {
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault()
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, '')
        },
        input(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style lang="scss">
.v-text-field--outlined {

    fieldset {
        border-width: 1px;
    }
}

.v-text-field--outlined.v-input--is-verify fieldset {
    border-color: var(--v-primary-base);
    border-width: 2px;
}

.input-field-label {
    font-size: 14px;
}

.v-input input,
.v-input textarea,
.theme--dark.v-input input, 
.theme--dark.v-input textarea {
    color: var(--v-primary-base);
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin: 0px;
}

.input-field {

    .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
        color: #000000;
    }

    .v-icon,
    .v-input__icon {
      color: #fbb70d;
    }

    .v-input__slot {
      margin: 0px;

        .v-select__slot {
            .v-select__selections {
                color: #000000;

                .v-select__selection--disabled {
                    color: #c9c9c9 !important;
                }
            }

            .v-icon--disabled {
                color: #c9c9c9 !important;
            }
        }
    }

    .v-input__append-outer,
    .v-input__prepend-inner {
        margin-top: 0px !important;
        align-self: center;
    }
    .v-input__append-inner {
        margin: 0px;
        align-self: center;
    }

    &.v-input--is-readonly {
        input {
            color: #3A3A3A !important;
            opacity: 0.7;
        }

        .v-input__slot {
            background-color: rgba(0, 0, 0, 0.1);
        }

    }

    .v-input__control {

        .v-input__slot {
            min-height: 40px;
            height: 40px;
        }

        .v-text-field__details {
            margin-bottom: 0px !important;

            .v-messages__message {
                font-size: 11px;
            }
        }
    }

}
</style>
