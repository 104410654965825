<template>
<!--  <div class="d-flex">-->
    <v-row v-if="this.showWatchVideo" class="watch-video-container">
      <div class="watch-video-label">
        <label>{{ label + ' ' + $t(`label.clickToKnowMore`) }}</label>
      </div>
      <div class="watch-video-button">
        <app-button :title="$t(`button.watchVideo`)" :action="this.watchVideo"></app-button>
      </div>
      <div class="watch-video-close" @click="this.closeWatchVideo">
        <img src="/static/image/icon/btn-close-watch-video.png" alt="close-watch-video" />
      </div>
    </v-row>
<!--  </div>-->
</template>

<script >
  export default {
    name: 'WatchTutorialVideo',
    props: {
      label: {
        type: String,
        required: true,
        default: ''
      },
      videoUrl: {
        type: String,
        required: true,
        default: ''
      }
    },
    data: () => ({
      showWatchVideo: true
    }),
    methods: {
      watchVideo() {
        if(this.videoUrl) {
          window.open(this.videoUrl, '_blank');
        }
      },
      closeWatchVideo() {
        this.showWatchVideo = false
      }
    }
  }
</script>

<style lang="scss">
  .watch-video-container {
    background-color: var(--v-background-lighten1);
    padding: 12px;
    align-items: center;
    margin: 20px 0 0;
    position: relative;

    .watch-video-label {
      width: calc(100% - 160px);
      font-size: 15px;
      font-weight: bold;

      @media screen and (max-width: 767px) {
        width: calc(100% - 128px);
        font-size: 13px;
      }
    }

    .watch-video-button {
      display: flex;
      width: 160px;
      text-align: right;
      justify-content: end;

      @media screen and (max-width: 767px) {
        width: 128px;
      }

      button {
        width: 152px;
        font-size: 14px !important;
        text-transform: uppercase !important;
        color: white !important;
        background-color: #f47922 !important;

        @media screen and (max-width: 767px) {
          width: 120px;
          font-size: 12px !important;
        }
      }
    }

    .watch-video-close {
      display: flex;
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;

      @media screen and (max-width: 767px) {
        right: -4px;
      }
      img {
        width: 26px;
        height: 26px;
      }
    }
  }
</style>
