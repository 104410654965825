<template>
    <v-btn class="pa-2" :disabled="disabled" :class="customClass"
           :max-width="maxWidth" @click="action">{{ title }}
    </v-btn>
</template>

<script>
    export default {
        name: 'appButton',
        props: {
            title: {
                type: String,
                required: true,
                default: ''
            },
            action: {
                type: Function,
                required: true,
                default: () => ({})
            },
            maxWidth: {
                type: Number,
                required: false,
                default: undefined
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            customClass: {
                type: String,
                required: false,
                default: 'buttonPrimary theme-button theme--light subtitle-1'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .secondary_button {
        background: transparent !important;
        text-decoration: underline;
        color: var(--v-navigator_active-base) !important;
        box-shadow: none!important;
    }

    .v-btn.theme-button {
        text-transform: capitalize;
        border-radius: 10px;
        height: auto;
        font-size: 16px;
    }

    @media (max-width: 599px) {
        .v-btn.dialog-button {
            width: 100% !important;
        }
    }
</style>
