<template>
<v-dialog persistent :value="dialogShow" :max-width="maxWidth">
    <v-card class="dialog-card">

        <v-row no-gutters justify="space-between" :class="$vuetify.breakpoint.mdAndDown ? 'dialog-header' :'dialog-header'">
            <v-col cols="10" class="d-flex flex-column pl-6 py-4">
                <img v-if="isRegSuccess" src="/static/svg/logo.png" alt="krikya cricket exchange" width="133" height="30" />
                <label v-if="!isAuth && !isRegSuccess" class="dialog-title text-capitalize d-block full-width white--text">
                    {{ title }}
                </label>
                <v-row no-gutters class="pt-2 pb-0" v-if="subtitle">
                    <label class="full-width dialog-subtitle">{{subtitle}}</label>
                </v-row>
            </v-col>
            <v-col cols="2" class="d-flex justify-content-center align-content-center">
                <v-icon class="full-width" :class="['white--text dialog-close-icon', this.header_title == 'register' ? 'register' : this.header_title == 'login' ? 'login' : '']" @click="closeAction" v-if="closeAction != null">
                    mdi-close
                </v-icon>
            </v-col>
        </v-row>

        <!-- v-if is to allow component to reinitialize it Life cycle -->
        <v-row no-gutters class="dialog-row" :class="customClass" v-if="dialogShow">
            <slot></slot>
        </v-row>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: 'appDialog',
    props: {
        header_title: {
            type: String,
            required: false
        },
        isAuth: {
            type: Boolean,
            default: false,
            required: false
        },
        closeAction: {
            required: false,
            default: null
        },
        title: {
            type: String,
            required: true,
            default: ''
        },
        maxWidth: {
            type: Number,
            required: false,
            default: 400
        },
        minHeight: {
            type: Number,
            required: false,
            default: 500
        },
        dialogShow: {
            type: Boolean,
            required: true,
            default: false
        },
        customClass: {
            type: String,
            required: false,
            default: ''
        },
        openRegisterDialog: {
            type: Function,
            default: () => ({})
        },
        openLoginDialog: {
            type: Function,
            default: () => ({})
        },
        isRegSuccess: {
            type: Boolean,
            default: false,
            required: false
        },
        subtitle: {
            type: String,
            required: false,
            default: ""
        }
    }
}
</script>

<style>
/* .logo-color {
        .path.b {
            fill: #fddd04;
        }
    } */
.v-dialog {
    overflow: hidden;
}

.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 97%;
}
</style>

<style lang="scss" scoped>
.dialog-logo {
    width: 133px;
    height: 22px;
    padding-top: 4px;
}

.dialog-card {
    transition: all .3s .3s ease-in-out;
    opacity: 1;
    font-size: 12px;

    .dialog-title {
        font-size: 16px;
    }

    .dialog-subtitle {
        font-size: 14px;
    }
}

.dialog-header {
    position: relative;
    background: var(--v-primary-base);
    border: 1px solid var(--v-primary-base);
}

.dialog-close-icon {
    cursor: pointer;
}

.dialog-close-icon:hover {
    transition: .5s ease-out;
    background-color: var(--v-primary-lighten1);
}

.dialog-row {
    overflow-y: auto;
    scrollbar-width: thin;
    padding: 16px 12px;
}

.dialog-divider {
    border-width: 0.8px !important;
    border-color: #ACACAC !important;
    height: 100%;
}

@media (max-height: 600px) {
    .dialog-row {
        max-height: 300px;
    }
}

@media (max-height: 400px) {
    .dialog-row {
        height: 200px;
    }
}
</style>
